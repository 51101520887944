@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  font-family: "Roboto", sans-serif;
}
html {
  overflow-x: hidden;
  scroll-behavior: smooth;
}
.main {
  margin-top: -100px;
  min-height: 120vh;
  width: 100%;
  background-image: url("https://cdn.pixabay.com/photo/2020/07/08/04/12/work-5382501_640.jpg");
  background-attachment: fixed;
  background-size: cover;
}

.main-2 {
  min-height: 120vh;
  width: 100%;
  background-color: rgba(81, 76, 217, 0.831);
}

.title {
  font-size: 80px;
  font-weight: 700;
  width: 300px;
  position: absolute;
  top: calc(40%);
  left: calc(20%);
  line-height: 100%;
  color: white;
}

.title span {
  color: rgb(30, 30, 30);
}

.sub-title {
  color: white;
  font-size: 23px;
  letter-spacing: 3px;
  font-weight: 600;
  position: absolute;
  top: calc(100%);
  left: calc(20%);
}

.services-container-home {
  width: auto;
  text-transform: uppercase;
  padding: 60px 20%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;
  background-color: rgb(63, 63, 83);
}

.service-home-title {
  width: 50px;
  font-size: 70px;
  line-height: 100%;
  color: white;
  font-weight: 500;
  border-top: 10px solid white;
  padding-top: 20px;
}

.services-home {
  display: flex;
  margin-top: 60px;
  gap: 11.5vw;

  font-size: 20px;
  font-weight: 300;
  color: white;
}

.services-home p {
  font-size: 18px;
  width: 50px;
  border-top: 8px solid dodgerblue;
  padding-top: 20px;
  letter-spacing: 4px;
}

.wrapper {
  min-height: 70vh;
  width: auto;
  background-image: url("https://cdn.pixabay.com/photo/2012/03/01/01/42/hands-20333_1280.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.section-3 {
  padding: 150px 20%;
  min-height: 70vh;
  width: auto;
  background-color: rgba(255, 255, 255, 0.564);
}

.section3-span {
  height: 10px;
  padding-top: 15px;
  width: 60px;
  border-top: 10px solid rgb(9, 73, 137);
}

.section3-title {
  font-size: 70px;
  font-weight: 600;
  line-height: 100%;
  text-transform: uppercase;
}

.sec3-para {
  margin: 40px 0px;
  font-size: 20px;
  line-height: 140%;
  color: rgb(39, 39, 39);
  opacity: 0.9;
  font-weight: 400;
}

.section-4 {
  min-height: 30vh;
  width: auto;
  background-color: white;
  padding: 60px 20%;
}

.section4-title {
  font-size: 70px;
  font-weight: 700;
  width: 70px;
  padding-top: 20px;
  line-height: 100%;
  border-top: 13px solid rgb(9, 73, 137);
}

.sec4-para {
  font-size: 17px;
  opacity: 0.8;
  margin-top: 30px;
  width: 80%;
  line-height: 140%;
  font-weight: 400;
}

.image-gallery {
  min-height: 100vh;
  padding: 70px 0px;
  width: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns */
  grid-template-rows: repeat(2, 1fr); /* Two rows */
}

.im-wrap img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.im-wrap p {
  color: white;
  font-size: 30px;
  position: relative;
  top: calc(50%);
  left: calc(0%);
  width: auto;
  visibility: hidden;
  /* transition: all 0.1s ease-in-out; */
  text-transform: uppercase;
}

.im-wrap:hover .p {
  visibility: visible;
  background-color: rgb(63, 63, 83);
  text-align: center;
}

.section-5 {
  margin-top: 80px;
  background-color: #ededed;
  min-height: 60vh;
  width: auto;
  padding: 80px 18%;
}

.wrap {
  width: auto;
  display: flex;
  justify-content: center;
}

.founder-details {
  display: flex;
  flex-direction: column;
}

.founder-details img {
  height: 100%;
  width: 80%;
  object-fit: cover;
}

.details {
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  width: 100%;
  /* background-color: red; */
  /* justify-content: center; */
  align-items: center;
  margin-top: 70px;
}

.d {
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 200px;
  /* background-color: red; */
}

.d i {
  font-size: 25px;
  color: rgb(9, 73, 137);
}

.d p {
  font-size: 18px;
  opacity: 0.8;
  letter-spacing: 2px;
  font-weight: 300;
  word-break: break-all;
}

.section-6 {
  min-height: 50vh;
  width: auto;
  background-image: url("https://www.teahub.io/photos/full/2-20197_new-york-city-winter-sunset-4k-hd.jpg");
  background-size: cover;
  background-attachment: fixed;
}

.section-6-wrap {
  min-height: 50vh;
  width: auto;
  background-color: rgba(60, 54, 227, 0.799);
  padding: 80px 20%;
}

.facts {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  margin: 60px 0px;
}

.fact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.numbers {
  font-size: 60px;
  font-weight: 600;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.det {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 2px;
}

.section-7 {
  min-height: 70vh;
  width: auto;
  background-color: #f5f5f5;
  padding: 100px 20%;
}

.form {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin: 30px 0px;
  padding: 50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.form input,
textarea {
  width: 70%;
  font-size: 16px;
  background-color: #ededed;
  color: rgb(9, 73, 137);
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
}

.form input::placeholder,
textarea::placeholder {
  font-size: 16px;
  color: rgb(9, 73, 137);
}

.form input:focus,
textarea:focus {
  background-color: rgb(9, 73, 137);
  outline: none;
  letter-spacing: 1px;
  color: white;
  font-size: 16px;
}

.buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;
}

.mail-button {
  background-color: rgb(9, 73, 137);
  color: white;
  text-decoration: none;
  padding: 8px 20px;
  border-radius: 10px;
  border: 1px solid white;
  transition: all 0.3s;
}

.mail-button2 {
  background-color: white;
  color: rgb(9, 73, 137);
  text-decoration: underline;
  padding: 8px 20px;
  border-radius: 10px;
  border: none;
  transition: all 0.3s;
}

.mail-button2:hover {
  color: tomato;
}

.mail-button:hover {
  background-color: white;
  border-color: rgb(9, 73, 137);
  color: rgb(9, 73, 137);
}

.footer {
  height: 30vh;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  color: white;

  background-color: rgb(19, 19, 26);
  font-weight: 300;
  letter-spacing: 2px;
}

.footer h1 {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 2px;
  color: white;
}

.socials {
  display: flex;
  margin-top: 20px;
  gap: 30px;
}

.socials i {
  font-size: 25px;
  color: white;
}

@media (max-width: 1000px) {
  .title {
    font-size: 65px;
    left: calc(50%);
    transform: translate(-50%, 0%);
    line-height: 120%;
    text-align: center;
    top: calc(30%);
    width: auto;
  }

  .sub-title {
    left: calc(50%);
    top: calc(79%);
    transform: translate(-50%, 0%);
    text-align: center;
    line-height: 120%;
  }

  .service-home-title {
    font-size: 50px;
  }

  .services-container-home {
    height: auto;
    text-align: center;
    padding: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .services-home {
    flex-direction: column;
    gap: 40px;
    text-align: center;
  }

  .section4-title {
    border: none;
    width: auto;
    text-align: center;
    border-bottom: 5px solid dodgerblue;
    padding-bottom: 30px;
    font-size: 50px;
  }
  .sec4-para {
    width: auto;
    text-align: center;
  }

  .section3-span,
  .section3-title {
    width: auto;
    border: none;
    text-align: center;
    font-size: 50px;
  }

  .service-home-title {
    border: none;
    width: auto;
    text-align: center;
  }

  .services-home p {
    width: auto;
    border-top: 2px solid dodgerblue;
  }

  .image-gallery {
    grid-template-columns: repeat(2, 1fr); /* Three columns */
    grid-template-rows: repeat(auto, 1fr); /* Two rows */
  }

  .wrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .founder-details {
    width: auto;
  }

  .founder-details img {
    width: 100%;
  }

  .d {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .details {
    justify-content: center;
    text-align: right;
  }

  .facts {
    gap: 40px;
  }

  .buttons {
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

@media (max-width: 600px) {
  .title {
    font-size: 55px;
  }
  .image-gallery {
    margin-top: 0px;
    grid-template-columns: repeat(1, 1fr); /* Three columns */
    grid-template-rows: repeat(auto, 1fr); /* Two rows */
  }

  .section-4 {
    height: auto;
  }

  .sec4-para {
    margin: 50px 0px;
  }
  .section-5 {
    margin-top: 30px;
  }

  .section-7 {
    padding: 80px 20px;
  }

  .form input,
  .form textarea {
    width: 100%;
  }
}
