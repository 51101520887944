* {
  margin: 0;
  padding: 0;
}

body {
  overflow-x: hidden;
}
.navbar {
  display: flex;
  justify-content: space-evenly;
  position: sticky;
  top: 0;
  align-items: center;
  width: 100%;
  padding: 10px 0px;
  background-color: rgba(65, 69, 176, 0.767);
  z-index: 9999;
}

.logo {
  color: white;
  height: 60px;
  width: auto;
  object-fit: cover;
}

.nav-items {
  display: flex;
  list-style-type: none;
  gap: 30px;
  text-decoration: none;
  transition: all 0.3s;
}

.nav-link {
  text-decoration: none;
  font-size: 17px;
  color: white;
}

.icon-menu {
  --gap: 5px;
  --height-bar: 2.5px;
  --pos-y-bar-one: 0;
  --pos-y-bar-three: 0;
  --scale-bar: 1;
  --rotate-bar-one: 0;
  --rotate-bar-three: 0;
  width: 25px;
  display: flex;
  flex-direction: column;
  gap: var(--gap);
  cursor: pointer;
  position: relative;
  z-index: 999999;
  display: none;
}

.bar {
  position: relative;
  height: var(--height-bar);
  width: 100%;
  border-radius: 0.5rem;
  background-color: #271f31;
  z-index: 999999;
}

.bar--1 {
  top: var(--pos-y-bar-one);
  transform: rotate(var(--rotate-bar-one));
  transition: top 200ms 100ms, transform 100ms;
  z-index: 999999;
}

.bar--2 {
  transform: scaleX(var(--scale-bar));
  transition: transform 150ms 100ms;
  z-index: 999999;
}

.bar--3 {
  bottom: var(--pos-y-bar-three);
  transform: rotate(var(--rotate-bar-three));
  transition: bottom 200ms 100ms, transform 100ms;
  z-index: 999999;
}

.check-icon:checked + .icon-menu > .bar--1 {
  transition: top 200ms, transform 200ms 100ms;
}

.check-icon:checked + .icon-menu > .bar--3 {
  transition: bottom 200ms, transform 200ms 100ms;
}

.check-icon:checked + .icon-menu {
  --pos-y-bar-one: calc(var(--gap) + var(--height-bar));
  --pos-y-bar-three: calc(var(--gap) + var(--height-bar));
  --scale-bar: 0;
  --rotate-bar-one: 45deg;
  --rotate-bar-three: -45deg;
}
/* FIN ICON-MENU */
.check-icon {
  display: none;
}
@media (max-width: 1200px) {
  .icon-menu {
    display: inline-flex;
  }
  .toggle {
    display: block;
  }
  .nav-items {
    position: absolute;
    height: 100vh;
    z-index: 9999;
    width: 100%;
    background-color: rgb(81, 76, 217);
    top: 0;
    left: 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    visibility: hidden;
    transform: translateX(100%);
    font-size: 20px;
  }

  .active {
    visibility: visible;
    transform: translateX(0%);
    font-size: 20px;
  }
  .active li .nav-link {
    font-size: 19px;
  }
}
